import React, { useState } from "react";
import Switch from "react-switch";
import {
    useUpdateGeneralMutation,
  } from "services/riskManagement";
  import { toast } from 'react-toastify';

function ToggleYesNo(props) {
  const notify = text => toast(text);
  const { defaultValue, whichChange, id, option_value, option_value_medium, option_value_high, setting_id } = props
  const [checked, setChecked] = useState(defaultValue === 'True' ? true : false );
  const [updatePost, { isLoadingPost }] = useUpdateGeneralMutation();
  const [updateStatus, setUpdateStatus] = useState(defaultValue === 'True' ? true : false );
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
    setUpdateStatus(nextChecked)
  };
  const handleOptionChange = async (id, optionValue, optionValueMedium, optionValueHigh, settingId) => {
    let obj = {
      id,
      option_value: optionValue,
      option_value_medium : optionValueMedium,
      option_value_high : optionValueHigh,
      setting_id: settingId,
    };
    const response = await updatePost(obj);
    if(response?.data?.status){
      notify(response?.data?.message)
    }
  };
  const prePareForCall = () => {
    let e = updateStatus;
    if(whichChange === 'option_value'){
        handleOptionChange(id, e ? 'True' : 'False', option_value_medium, option_value_high, setting_id)
    }

    if(whichChange === 'option_value_medium'){
        handleOptionChange(id, option_value, e ? 'True' : 'False', option_value_high, setting_id)
    }

    if(whichChange === 'option_value_high'){
        handleOptionChange(id, option_value, option_value_medium, e ? 'True' : 'False', setting_id)
    }
  }

  return (
    <>
      <label className="labelSwitch">
        <Switch
          onChange={handleChange}
          checked={checked}
          className="react-switch"
          height={16}
          width={32}
          onColor="#8b3d16"
          onHandleColor="#f8610e"
        />
        <span className={`pointer text-${checked ? "green" : "red"}`}>
          {checked ? "Yes" : "No"}
        </span>
      </label>
      <button className="button" type="sumit" onClick={prePareForCall}>
        Submit
      </button>
    </>
  );
}

export default ToggleYesNo;
