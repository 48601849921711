import { createSlice } from '@reduxjs/toolkit'

const selectedBroker = 5
const initialState = {
  selectedBroker : selectedBroker
}

const brokerSlice = createSlice({
  name: 'broker',
  initialState,
  reducers: {
    setBroker : (state, action) => {
      state.selectedBroker = action.payload
    }
  },
})

export const { setBroker } = brokerSlice.actions

export default brokerSlice.reducer

//export const selectToasts = state => state.toasts
