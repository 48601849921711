import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

function LineChart({ dataSeries }) {
  const [seriesArray, setSeriesArray] = useState([]);
  const chartComponent = useRef({});

  useEffect(() => {
    // console.log('dataSeries ', dataSeries)
    setSeriesArray(dataSeries.datasets)
    const chart = chartComponent.current?.chart;
    console.log('chart ', chart)
    if (chart) chart.reflow(true);

  }, [dataSeries])
  
  const options = {

    title: {
        text: 'STRATEGY PERFORMANCE',
        align: 'left',
        style : {
          color : '#FFF'
        }
    },
    yAxis: [{
        title: {
            text: 'Profit',
            style: {
            color : '#FFF'
            }
        },
        labels: {
            style: {
                color: '#FFF'
            }
        },
        gridLineWidth: 0,
        minorGridLineWidth: 0,
    },  {
            title: {
                text: 'Return',           
                style: {
                color : '#FFF'
            },
        },
        gridLineWidth: 0,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        opposite: true,
        labels: {
            style: {
                color: '#FFF'
            }
        },
    }],
    xAxis: {
        type: 'datetime',
        labels: {
            style: {
                color: '#FFF'
            }
        },
    },

    legend: {
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'top',
        itemStyle: {
            color: 'white'
        }
    },

    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            },
            pointStart: 2010
        }
    },

    series: seriesArray,
    chart: {
      type: 'line',
      height: 500,
      backgroundColor: 'rgba(0,0,0,0)',
      borderWidth : 0,
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 500
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

}

  return (
    <>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        // constructorType={"stockChart"}
        options={options}
        allowChartUpdate={true}
        // immutable={false}
         updateArgs={[true, true, true]}
      />
    </>
  );
}
export default LineChart;
