import React, {useEffect, useState} from "react";
import Select from 'react-select'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getIdToken } from "utils";
import { getConfig } from "config/app-config";

function TradeForm() {
    const [brokerOptions,setBrokerOptions]=useState([]);
    const [selectedBroker,setSelectedBroker]=useState();
    const [selectedRisk,setSelectedRisk]=useState();
    const [brokersData,setBrokersData]=useState([]);
    const [userBrokersData,setUserBrokers]=useState([]);
    const [publicId, setPublicId] = useState('');


    function getBrokers()
    {
        var userData=JSON.parse(localStorage.getItem('credentials'));
        setPublicId(userData.public_id);
        fetch(`${getConfig().API_URL}/brokers/`, {
            method: 'GET',
            headers: {
              authorization:getIdToken()
            },
          }).then(response => response.json())
          .then(data => {
            setBrokersData(data);
            var opt=[];
            var i=0;
            data.forEach(element => {
              
              var obj={
                value:element.id,
                label: upperCaseDemoLive(element.title)
              }           
                opt.push(obj);
          });
          setBrokerOptions(opt);
          });
    }
     function getUserBrokers()
    {
        var userData=JSON.parse(localStorage.getItem('credentials'));
        setPublicId(userData.public_id);
        fetch(`${getConfig().API_URL}/users/brokers/${userData.public_id}`, {
            method: 'GET',
            headers: {
              authorization:getIdToken()
            },
          }).then(response => response.json())
          .then(data => {
            setUserBrokers(data);
                var i=0;
                data.forEach(element=>{
                    if(++i==1)
                    {
                        setSelectedCategory(element.broker.name);
                        return; 
                    }
                })
          });
    }
    function getUserProfile()
    {
        var userData=JSON.parse(localStorage.getItem('credentials'));
        fetch(`${getConfig().API_URL}/users/${userData.public_id}`, {
            method: 'GET',
            headers: {
              authorization:getIdToken(),
              'Content-Type': 'application/json',
            },
          }).then(response => response.json())
          .then(data => {
            if(data)
            {   
                switch(data.risk)
                    {
                        case 1:
                            setSelectedRisk({
                                value:1,
                                label:'Conservative'
                            })
                        break;
                        case 2:
                            setSelectedRisk({
                                value:2,
                                label:'Moderate'
                            })
                        break;
                        case 3:
                            setSelectedRisk({
                                value:3,
                                label:'Aggressive'
                            })
                        break;

                    }
            }

            });
    }
    function getEpicTypes()
    {
        fetch(`${getConfig().API_URL}/epicType/`, {
            method: 'GET',
            headers: {
              authorization:getIdToken(),
              'Content-Type': 'application/json',
            }, 
          }).then(response => response.json())
          .then(data => {
        //    console.log("Epic data",data);

            });
    }
    function updateRisk(risk)
    {
        if(!risk)
        {
            alert('Please select the risk profile you want to update.');
        }
        else
        {
            // console.log(risk);
            var userData=JSON.parse(localStorage.getItem('credentials'));
            fetch(`${getConfig().API_URL}/users/${userData.public_id}`, {
                method: 'PATCH',
                headers: {
                  authorization:getIdToken(),
                  'Content-Type': 'application/json',
                },
                // headers: {'Content-Type':'application/json'},
                body:JSON.stringify({
                    "risk":selectedRisk.value
                })
              }).then(response => response.json())
              .then(data => {
                if(data && data!=null)
                {
                   alert('Profile risk updated successfully');
                }
                
              });
        }
    }
    function addBroker()
    {
        if(!selectedBroker)
        {
            alert('Please select a broker to add');
        }
        else
        {
        var userData=JSON.parse(localStorage.getItem('credentials'));
              console.log(userData);
            fetch(`${getConfig().API_URL}/users/brokers/${userData.public_id}`, {
                method: 'POST',
                headers: {
                  authorization:getIdToken(),
                  'Content-Type': 'application/json',
                },
                // headers: {'Content-Type':'application/json'},
                body:JSON.stringify({
                    "broker_id":selectedBroker.value
                })
              }).then(response => response.json())
              .then(data => {
                if(data && data!=null)
                {
                    alert(data.message);
                }
                else
                {
                    alert('Broker added successfully');
                getUserBrokers();

                }
              });
            // console.log(selectedBroker);

        }
    }
    function removeBroker(brokerId)
    {
        if(!brokerId)
        {
            alert('Please select a broker to remove');
        }
        else
        {
            if(confirm('Are you sure to remove the broker ?'))
            {
                var userData=JSON.parse(localStorage.getItem('credentials'));
              fetch(`${getConfig().API_URL}/users/brokers/${userData.public_id}?user_broker_id=${brokerId}`, {
                  method: 'DELETE',
                  headers: {
                    authorization:getIdToken(),
                    'Content-Type': 'application/json',
                  }
                }).then(response => response.json())
                .then(data => {
                    if(data && data.status=='success')
                    {
                        alert(data.message);
                        getUserBrokers();
                    }
                });
              // console.log(selectedBroker);
            }
        }
    }
    useEffect(()=>getBrokers(),[]);
    useEffect(()=>getEpicTypes(),[]);
    useEffect(()=>getUserProfile(),[]);
    useEffect(()=>getUserBrokers(),[]);

    const riskProfileOptions = [
        { value: '1', label: 'Conservative' },
        { value: '2', label: 'Moderate' },
        { value: '3', label: 'Aggressive' }
    ]
   

    const [selectedCategory, setSelectedCategory] = useState('');

    // const isIgLive      = selectedCategory === 'iglive';
    // const isIgDemo      = selectedCategory === 'igdemo';
    // const isOandaLive   = selectedCategory === 'oandalive';
    // const isOandaDemo   = selectedCategory === 'oandademo';

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [lfShow1, setLFShow1] = useState(false);
    const [lfShow2, setLFShow2] = useState(false);
    const [lfShow3, setLFShow3] = useState(false);
    const [lfShow4, setLFShow4] = useState(false);
    const upperCaseDemoLive = (string=null) => {
        if(string){
          if(string.toLowerCase().includes('(live)')){
            let replacedWord = string.replace('(live)', '(LIVE)')
            return replacedWord;
          }
    
          if(string.toLowerCase().includes('(demo)')){
            let replacedWord = string.replace('(demo)', '(DEMO)')
            return replacedWord;
          }
        }
      }
    return (
        <div className="divTransaction">
            <div className='row align-items-center'>
                <div className='col-12 col-lg-6'>
                    <div class="fieldWrapper">
                        <label>Risk Profile</label>
                        <div className="fieldSet">
                            <Select className="selectBox" value={selectedRisk} options={riskProfileOptions} onChange={(element)=>setSelectedRisk(element)} />
                            <button type="submit" onClick={(e)=>updateRisk(selectedRisk)}>Submit</button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-6'>
                    <div class="fieldWrapper">
                        <label>Broker</label>
                        <div className="fieldSet">
                            <Select className="selectBox" options={brokerOptions} onChange={(element)=>setSelectedBroker(element)} />
                            <button type="submit" onClick={addBroker}>Add Broker</button>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div class="fieldWrapper">
                        <label>My Broker</label>
                        <ul class="buttonsAccount my-0">
                            {
                                userBrokersData?.map((element)=>
                                <li>
                                <a href="javascript:;" onClick={() => setSelectedCategory(element?.broker?.title)} className={`categories ${selectedCategory==element?.broker?.title ? 'active' : ''}`}>{element?.broker?.title}</a>
                                <div className="buttonsLinks">
                                    <a href="javascript:;" onClick={() => setLFShow1(true)}>Credentials</a>
                                    <span>|</span>
                                    <a href="javascript:;" onClick={(e)=>removeBroker(element.id)}>Delete</a>
                                </div>
                                </li>
                                )
                            }
                           
                            
                        </ul>
                       
                        <Modal
                            show={lfShow1}
                            onHide={() => setLFShow1(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                        <Modal.Body>
                            <div className="modal-closebar">
                                <i className="fas fa-times" onClick={() => setLFShow1(false)}></i>
                            </div>
                            <div className="modal-header">
                                <h2 className="modal-title">Credentials</h2>
                                <a href="javascript:;" className="modal-link">Test Connection</a>
                            </div>
                            <div className="row dualformRow">
                                <div className="col-12 col-lg-6 border-right-side">

                                    <h2>IG</h2>

                                    <div className="formWrapperCS01">
                                        <div className="fieldWrapper">
                                            <label>User Name</label>
                                            <input type="text" className="form-control" placeholder="User Name" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>API Key</label>
                                            <input type="text" className="form-control" placeholder="API Key" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Password</label>
                                            <input type="password" className="form-control" placeholder="Password" />
                                        </div>
                                    </div>

                                    <div className="buttonGroup">
                                        <button type="submit" className="button orange">Live</button>
                                        <button type="submit" className="button">Demo</button>
                                    </div>

                                </div>
                                <div className="col-12 col-lg-6">

                                    <h2>Oanda</h2>

                                    <div className="formWrapperCS01">
                                        <div className="fieldWrapper">
                                            <label>Token</label>
                                            <input type="text" className="form-control" placeholder="Token" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Default Account</label>
                                            <input type="text" className="form-control" placeholder="Default Account" />
                                        </div>
                                    </div>
                                    
                                    <div className="buttonGroup">
                                        <button type="submit" className="button">Live</button>
                                        <button type="submit" className="button">Demo</button>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        </Modal>

                        <Modal
                            show={lfShow2}
                            onHide={() => setLFShow2(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                        <Modal.Body>
                            <div className="modal-closebar">
                                <i className="fas fa-times" onClick={() => setLFShow2(false)}></i>
                            </div>
                            <div className="modal-header">
                                <h2 className="modal-title">Credentials</h2>
                                <a href="javascript:;" className="modal-link">Test Connection</a>
                            </div>
                            <div className="row dualformRow">
                                <div className="col-12 col-lg-6 border-right-side">

                                    <h2>IG</h2>

                                    <div className="formWrapperCS01">
                                        <div className="fieldWrapper">
                                            <label>User Name</label>
                                            <input type="text" className="form-control" placeholder="User Name" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>API Key</label>
                                            <input type="text" className="form-control" placeholder="API Key" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Password</label>
                                            <input type="password" className="form-control" placeholder="Password" />
                                        </div>
                                    </div>

                                    <div className="buttonGroup">
                                        <button type="submit" className="button">Live</button>
                                        <button type="submit" className="button orange">Demo</button>
                                    </div>

                                </div>
                                <div className="col-12 col-lg-6">

                                    <h2>Oanda</h2>

                                    <div className="formWrapperCS01">
                                        <div className="fieldWrapper">
                                            <label>Token</label>
                                            <input type="text" className="form-control" placeholder="Token" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Default Account</label>
                                            <input type="text" className="form-control" placeholder="Default Account" />
                                        </div>
                                    </div>
                                    
                                    <div className="buttonGroup">
                                        <button type="submit" className="button">Live</button>
                                        <button type="submit" className="button">Demo</button>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        </Modal>

                        <Modal
                            show={lfShow3}
                            onHide={() => setLFShow3(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                        <Modal.Body>
                            <div className="modal-closebar">
                                <i className="fas fa-times" onClick={() => setLFShow3(false)}></i>
                            </div>
                            <div className="modal-header">
                                <h2 className="modal-title">Credentials</h2>
                                <a href="javascript:;" className="modal-link">Test Connection</a>
                            </div>
                            <div className="row dualformRow">
                                <div className="col-12 col-lg-6 border-right-side">

                                    <h2>IG</h2>

                                    <div className="formWrapperCS01">
                                        <div className="fieldWrapper">
                                            <label>User Name</label>
                                            <input type="text" className="form-control" placeholder="User Name" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>API Key</label>
                                            <input type="text" className="form-control" placeholder="API Key" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Password</label>
                                            <input type="password" className="form-control" placeholder="Password" />
                                        </div>
                                    </div>

                                    <div className="buttonGroup">
                                        <button type="submit" className="button">Live</button>
                                        <button type="submit" className="button">Demo</button>
                                    </div>

                                </div>
                                <div className="col-12 col-lg-6">

                                    <h2>Oanda</h2>

                                    <div className="formWrapperCS01">
                                        <div className="fieldWrapper">
                                            <label>Token</label>
                                            <input type="text" className="form-control" placeholder="Token" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Default Account</label>
                                            <input type="text" className="form-control" placeholder="Default Account" />
                                        </div>
                                    </div>
                                    
                                    <div className="buttonGroup">
                                        <button type="submit" className="button orange">Live</button>
                                        <button type="submit" className="button">Demo</button>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        </Modal>

                        <Modal
                            show={lfShow4}
                            onHide={() => setLFShow4(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                        <Modal.Body>
                            <div className="modal-closebar">
                                <i className="fas fa-times" onClick={() => setLFShow4(false)}></i>
                            </div>
                            <div className="modal-header">
                                <h2 className="modal-title">Credentials</h2>
                                <a href="javascript:;" className="modal-link">Test Connection</a>
                            </div>
                            <div className="row dualformRow">
                                <div className="col-12 col-lg-6 border-right-side">

                                    <h2>IG</h2>

                                    <div className="formWrapperCS01">
                                        <div className="fieldWrapper">
                                            <label>User Name</label>
                                            <input type="text" className="form-control" placeholder="User Name" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>API Key</label>
                                            <input type="text" className="form-control" placeholder="API Key" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Password</label>
                                            <input type="password" className="form-control" placeholder="Password" />
                                        </div>
                                    </div>

                                    <div className="buttonGroup">
                                        <button type="submit" className="button">Live</button>
                                        <button type="submit" className="button">Demo</button>
                                    </div>

                                </div>
                                <div className="col-12 col-lg-6">

                                    <h2>Oanda</h2>

                                    <div className="formWrapperCS01">
                                        <div className="fieldWrapper">
                                            <label>Token</label>
                                            <input type="text" className="form-control" placeholder="Token" />
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Default Account</label>
                                            <input type="text" className="form-control" placeholder="Default Account" />
                                        </div>
                                    </div>
                                    
                                    <div className="buttonGroup">
                                        <button type="submit" className="button">Live</button>
                                        <button type="submit" className="button orange">Demo</button>
                                    </div>

                                </div>
                            </div>
                        </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default TradeForm;