import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import moment from "moment-timezone";

const Loc = ({ zone, country, onTimeZoneChange, isRunning, allTime, selectedTimeZone }) => {
  console.log("allTime={allTime} ", allTime[country])
  var zone_name =  moment.tz.guess();
 
  const popover = (
    <Popover id="popover-basic" className="popup_basic">
      <a className="cross_btn"><i className="fas fa-plus"></i></a>
      <Popover.Header as="h3">{country}</Popover.Header>
      <Popover.Body>
        <strong>Start {allTime[country]?.start?.format('kk:mm:ss') + " " + moment.tz(zone_name).zoneAbbr()}</strong>
        <br />
        <strong>End {allTime[country]?.stop?.format('kk:mm:ss') + " " + moment.tz(zone_name).zoneAbbr()}</strong>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger placement="top" overlay={popover}>
      <li>
        <label for={`${zone}`} className="time-checkbox">
          <input
            id={`${zone}`}
            onClick={onTimeZoneChange}
            name="time_zone"
            type="radio"
            value={country}
            disabled={isRunning}
            checked={ selectedTimeZone === country}
          />
          <span className="text">{country}</span>
        </label>
      </li>
    </OverlayTrigger>
  );
};

export default Loc;