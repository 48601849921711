// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getConfig } from "config/app-config";
import { pushToast } from '../slices/toastSlice'
import { toastMessages, toastTypes } from '../fixtures'
import { getIdToken } from '../utils'
// Define a service using a base URL and expected endpoints
export const riskManagementApi = createApi({
  reducerPath: "riskManagementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${getConfig().API_URL}`,
    prepareHeaders: (headers) => {
      const token = getIdToken();
      if (token) {
        headers.set("authorization", `${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRiskManagement: builder.query({
      query: (name) => ({
        url: `generalSettings/`,
        method: "GET",
      }),
    }),
    async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
        } catch (error) {
          dispatch(
            pushToast({
              type: toastTypes.error,
              message:
                error?.error?.data?.message || toastMessages.common.error,
            })
          )
        }
      },
    updateGeneral : builder.mutation({
        query: post => ({
          url: `generalSettings/`,
          method: 'PATCH',
          body: post
        })
      }),
      getRules: builder.query({
        query: (name) => ({
          url: `generalSettings/rules`,
          method: "GET",
        }),
        providesTags: ['tadingRules'],
      }),
      updateTradeRules : builder.mutation({
        query: rule => ({
          url: `generalSettings/rules`,
          method: 'PATCH',
          body: rule
        }),
        invalidatesTags: ['tadingRules']
      }),
  }),

});

export const { 
  useGetRiskManagementQuery, 
  useUpdateGeneralMutation, 
  useGetRulesQuery, 
  useUpdateTradeRulesMutation 
} = riskManagementApi
