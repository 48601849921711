import React, {useRef, useState} from "react";
import ToggleYesNo from 'components/common/ToggleYesNo';
import NumericField from 'components/common/NumericField';
import ThousandField from 'components/common/ThousandField';
import StopLoss from 'components/common/stoploss';
import MaxTrades from 'components/common/maxtrades';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select'
import { useEffect } from "react";
import { getIdToken } from "utils";
import { getConfig } from "config/app-config";
import ReactSwitch from "react-switch";
import { useSelector } from "react-redux";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const items = [
    {
      id: 0,
      name: 'Cobol'
    },
    {
      id: 1,
      name: 'JavaScript'
    },
    {
      id: 2,
      name: 'Basic'
    },
    {
      id: 3,
      name: 'PHP'
    },
    {
      id: 4,
      name: 'Java'
    }
  ]


  
function TradeTable() {
    const [createEpicModal, setCreateEpicModal] = useState(false);
    const [userBrokersData,setUserBrokers]=useState([]);
    const [brokerOptions,setBrokerOptions]=useState();
    const [brokerEpics,setBrokerEpics]=useState();
    const [selectedBrokerEpic,setSelectedBrokerEpic]=useState();
    const [publicId,setPublicId]=useState([]);
    const [selectedBroker,setSelectedBroker]=useState();
    const [autoStopLoss,setAutoStopLoss]=useState(false);
    const [autoPosition,setAutoPosition]=useState(false);
    const [positionSize,setPositionSize]=useState('');
    const [stopLoss,setStopLoss]=useState('');
    const [maxTrades,setMaxTrades]=useState('');


    const [isOff, setIsOff] = useState(true);
    const handleToggle = () => { setIsOff(!isOff);  };


    const min = 1;
    const max = 10;

    const [value, setValue] = useState(1);

    const handleChange = event => {
    const value = Math.max(min, Math.min(max, Number(event.target.value)));
    setValue(value);
    };


    const [epicListing, setEpicListing] = useState({});

    const authInfo = useSelector(selector => selector.auth);
    const brokerInfo = useSelector(selector => selector.brokerSlice.selectedBroker);
    console.log(brokerInfo, 'broker info')
    function getUserBrokers()
    {
        const upperCaseDemoLive = (string=null) => {
            if(string){
              if(string.toLowerCase().includes('(live)')){
                let replacedWord = string.replace('(live)', '(LIVE)')
                return replacedWord;
              }
        
              if(string.toLowerCase().includes('(demo)')){
                let replacedWord = string.replace('(demo)', '(DEMO)')
                return replacedWord;
              }
            }
          }
        var userData=JSON.parse(localStorage.getItem('credentials'));
        setPublicId(userData.public_id);
        fetch(`${getConfig().API_URL}/users/brokers/${userData.public_id}`, {
            method: 'GET',
            headers: {
              authorization:getIdToken()
            },
          }).then(response => response.json())
          .then(data => {
            setUserBrokers(data);
                var i=0;
                var opt=[];
                data.forEach(element => {
                    var obj={
                        value:element.broker.id, 
                        label: upperCaseDemoLive(element.broker.title),
                        id: element.id,
                        portfolio_id: 'undefined' !== typeof element.user_broker_portfolio ? element.user_broker_portfolio[0].portfolio_id : ''
                      }
                    if(++i==1) 
                    {
                        setSelectedBroker(obj);
                    }

                                   
                      opt.push(obj);
                });
                setBrokerOptions(opt);
          });
    }

const [currentPage, setCurrentPage] = useState(0);
const [arrayChunks, setArrayChunks] = useState([]);
const [listOfEpics, setListOfEpics] = useState([]);

    useEffect(() => {
        let arr = []
        if (0 < userBrokersData.length) {
            userBrokersData.map((result, key) => {
            if (brokerInfo === result.broker_id) {
                    result.user_broker_portfolio.map((portfolios, key) => {
                        portfolios.portfolio.epics.map((epicsListss) => {
                            arr.push(epicsListss)
                        })
                    })
                }
            })
        }

        console.log(arr, 'epicicicicicicici')
        setListOfEpics(arr);

        let currentPage = 0;
        const chunkSize = 10;
        let arrayChunk = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            arrayChunk.push(arr.slice(i, i + chunkSize))
        }
        setArrayChunks(arrayChunk)

        // if ('undefined' !== typeof arrayChunk[currentPage]) {
            setEpicListing(arrayChunk[currentPage])
        // }
        
    }, [userBrokersData, brokerInfo])

function nextPage(event)
{
    if (0 === arrayChunks.length || currentPage == parseInt(arrayChunks.length) - 1) {
        return false;
    }
    let page = parseInt(currentPage) + 1
    setCurrentPage(page)
    setEpicListing(arrayChunks[page])
}

function previousPage(event)
{
    if (currentPage <= 0) {
        return false;
    }
    let page = parseInt(currentPage) - 1
    setCurrentPage(page)
    setEpicListing(arrayChunks[page])
}




const [epics, setEpics] = useState({})
const [searchEpic, setSearchEpic] = useState('')
const [showEpicSuggestions, setShowEpicSuggestions] = useState(false)

function getBrokerEpics(event){
    setSearchEpic(event.target.value);
    fetch(`${getConfig().API_URL}/brokers/epics/${selectedBroker?.value}?filter=`+event.target.value, {
        method: 'GET',
        headers: {
            authorization:getIdToken()
        },

    }).then(response => response.json())
    .then(data => {
      setEpics(data);
      setIsVisible(true)
      setShowEpicSuggestions(true);
    })
}

function setBrokerEpic(event)
{   
    setSelectedBrokerEpic(event.target.value)
    setSearchEpic(event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text)
    setShowEpicSuggestions(false);
}



function addUserBrokerEpic()
{
    if(!selectedBrokerEpic) {
        alert('Please add epic name.');
    }
    else if(!positionSize)
    {
        alert('Please add position size.');
    }
    else if(!maxTrades) {
        alert('Please add max trades.');
    }
    else
    {
    var userData=JSON.parse(localStorage.getItem('credentials'));
    setPublicId(userData.public_id);

    fetch(`${getConfig().API_URL}/users/epics/${userData.public_id}`, {
        method: 'POST',
        headers: {
          authorization:getIdToken(),
          'Content-Type': 'application/json'
        },
        body:JSON.stringify({
            "user_broker_id":parseInt(selectedBroker.id),//this is broker epic ID
            "epic":selectedBrokerEpic,//this is broker epic ID
            "epic_name":searchEpic,//this is broker epic ID
            "size":parseInt(positionSize),
            "stop_distance":isNaN(parseInt(stopLoss)) ? 0 : parseInt(stopLoss),
            "max_trade":parseInt(maxTrades),
            "automated_stop_loss":autoStopLoss,
            "auto_position":autoPosition,
            "portfolio_id": parseInt(selectedBroker.portfolio_id)
        })
      }).then(response => response.json())
      .then(data => {
        setCreateEpicModal(false)
        setSelectedBrokerEpic('')
        setSearchEpic('')
        setPositionSize('')
        setStopLoss('')
        setMaxTrades('')
        setAutoStopLoss(false)
        setAutoPosition(false)
        getUserBrokers()
      });  
    }



}

function deleteEpic(epicId)
{
    if(confirm('Are you sure want to delete this epic ?')) {
        if ('' === epicId || 'undefined' === typeof epicId) {
            return false;
        }
        fetch(`${getConfig().API_URL}/users/epics/${authInfo.user.public_id}?epic_id=${epicId}`, {
            method: 'DELETE',
            headers: {
              authorization:getIdToken(),
              'Content-Type': 'application/json'
            }
          }).then(response => response.json())
          .then(data => {
            getUserBrokers()
            
          });  
    }
}


useEffect(() => {
    getUserBrokers()
}, []);

const [searchEpicList, setSearchEpicList] = useState('');
const [epicSuggestions, setEpicSuggestions] = useState({});

function searchChange(event)
{   

    let value = event.target.value;
    setSearchEpicList(value)


    fetch(`${getConfig().API_URL}/brokers/epics/${selectedBroker?.value}?filter=`+value, {
        method: 'GET',
        headers: {
            authorization:getIdToken()
        },

    }).then(response => response.json())
    .then(data => {
        setEpicSuggestions(data)
        setIsVisible(true);
    })
}

function filterEpicChunks()
{
    setEpicListing({});
    let arr = []
    listOfEpics.map((result, key) => {
        if (null != result.epic_name.match(searchEpicList)) {
            arr.push(result)
        }
    })
    
    let currentPage = 0;
    const chunkSize = 10;
    let arrayChunk = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        arrayChunk.push(arr.slice(i, i + chunkSize))
    }
    setCurrentPage(currentPage);
    setArrayChunks(arrayChunk)

    if ('undefined' !== typeof arrayChunk[currentPage]) {
        // console.log(arrayChunk[currentPage], arrayChunk[currentPage].length, 'asdadsa')
        setEpicListing(arrayChunk[currentPage])
    }
}


const [disableStopLoss, setDisableStopLoss] = useState(false);
const wrapperRef = useRef(null);
const [isVisible, setIsVisible] = useState(true);

useEffect(() => {
document.addEventListener("click", handleClickOutside, false);
return () => {
    document.removeEventListener("click", handleClickOutside, false);
};
}, []);

const handleClickOutside = event => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsVisible(false);
    }
};

    return (
        <div className="tradeTable">
       
            <div className="rowSubhead">
            
                <div className="searchWrap">
                
                    
                <div className="searchWrapLeft">
                    <input className="searchField" type="text" placeholder="Search" value={searchEpicList} onChange={(event) => searchChange(event)} maxLength="10" />
                    {/* <ReactSearchAutocomplete
            items={items} 
            autoFocus
            /> */}
                    {'undefined' !== typeof epicSuggestions && 0 < epicSuggestions.length && isVisible? 
                    <ul className="autocomplete_ul" ref={wrapperRef}>
                        {epicSuggestions.map((value, key) => {
                            return <li onClick={() => {
                                setSearchEpicList(value.epic_name)
                                setIsVisible(false)
                                setEpicSuggestions({})
                            }}>{value.epic_name}</li>
                        })}
                    </ul>
                    : '' }
                    </div>
                    <button className="searchButton" type="button" onClick={() => filterEpicChunks()}><i className="fas fa-search"></i></button>
                    
                </div>
                <a className="button" href="javascript:;" onClick={(e)=>setCreateEpicModal(true)}>New Epic</a>
            </div>

            <div className="divergence-table table-responsive mb-0">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Epic</th>
                            <th scope="col">Position Size</th>
                            <th scope="col">Stop Loss</th>
                            <th scope="col">Max Trades</th>
                            <th scope="col">Auto Position Size</th>
                            <th scope="col">Auto Stop Loss</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {'undefined' !== typeof epicListing && 0 < epicListing.length ?
                            <>
                                {epicListing.map((result, key) => {
                                    return(
                                    <tr>
                                        <td>{result.epic_name}</td>
                                        <td><ThousandField name={'positionsize'} value={result.size} /></td>
                                        <td><StopLoss defaultvalue={result.stop_distance} /></td>
                                        <td><MaxTrades defaultvalue={result.max_trade} /></td>
                                        <td><ToggleYesNo /></td>
                                        <td><ToggleYesNo /></td>
                                        <td class="action">
                                            <i class="fas fa-trash-alt" onClick={() => deleteEpic(result.id)}></i>
                                        </td>
                                    </tr>)
                                })}
                            </>
                            
                        : ''}
                   
                    </tbody>
                </table>
                <ul className='pagination'>
                    <li onClick={(event) => previousPage(event)}><i className="fas fa-angle-left"></i></li>
                    {/* <li className='active'><span>{currentPage + 1}</span></li> */}
                    {arrayChunks.map((value, key) => {
                        if (currentPage === 0 && key === 0) {
                            return <li className={currentPage === key ? 'active' : ''}><span>{key + 1}</span></li>
                        } else {
                            if (key === currentPage + 1 || key === currentPage - 1 || key === currentPage) {
                                return <li className={currentPage === key ? 'active' : ''} 
                                onClick={() => {
                                setCurrentPage(key)
                                setEpicListing(arrayChunks[key])
                                }}><span>{key + 1}</span></li>
                            }
                        }
                    })}
                    <li onClick={(event) => nextPage(event)}><i className="fas fa-angle-right"></i></li>
                </ul>
            </div>
            <Modal
                            show={createEpicModal}
                            onHide={() => setCreateEpicModal(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                        <Modal.Body>
                            <div className="modal-closebar">
                                <i className="fas fa-times" onClick={() => setCreateEpicModal(false)}></i>
                            </div>
                            <div className="modal-header">
                                <h2 className="modal-title">New Epic</h2>
                            </div>
                            <div className="row dualformRow">
                                <div className="col-12 col-lg-12">
                                    <div className="formWrapperCS01">
                                      
                                        <div className="fieldWrapper">
                                            <label>Broker</label>
                                            <Select className="selectBox" value={selectedBroker} onChange={(element)=>setSelectedBroker(element)} options={brokerOptions}  />
                                        </div>
                                        {
                                            selectedBroker &&
                                            <div className="fieldWrapper">
                                            <label>Epic</label>
                                            <input type="text" onChange={(event)=>getBrokerEpics(event)} className="form-control" value={searchEpic} placeholder="Epic" onClick={() => setShowEpicSuggestions(true)}/>
                                            {'undefined' !== typeof epics && 0 < epics.length && isVisible ? 
                                                <ul className="autocomplete_ul" ref={wrapperRef}>
                                                    {epics.map((value, key) => {
                                                        return <li onClick={() => {
                                                            setSelectedBrokerEpic(value.epic)
                                                            setSearchEpic(value.epic_name)
                                                            setIsVisible(false)
                                                            setEpicSuggestions({})
                                                        }}>{value.epic_name}</li>
                                                    })}
                                                </ul>
                                                : '' }

                                            {0 < epics.length && showEpicSuggestions && false ? 
                                                <select className="form-control" value={selectedBrokerEpic} onChange={(event)=>setBrokerEpic(event)}>
                                                    <option value='' selected >Select Epic</option>
                                                    {epics.map((result, key) =>{
                                                        return <option value={result.epic} >{result.epic_name}</option>
                                                    })}
                                                </select>
                                            : ''}
                                        </div>
                                        }
                                        <div className="fieldWrapper">
                                            <label>Position Size</label>
                                            <input type="number" value={positionSize} onChange={(e)=>setPositionSize(e.target.value)} className="form-control" placeholder="Position Size" />
                                        </div>
                                        <div className="fieldWrapper">
                                            <label>Stop Loss</label>
                                            <input type="number" value={stopLoss} onChange={(e)=>setStopLoss(e.target.value)} disabled={disableStopLoss}  className="form-control" placeholder="Stop Loss" />
                                        </div>
                                        <div className="fieldWrapper">
                                            <label>Max Trades</label>
                                            <input type="number" value={maxTrades} onChange={(e)=>setMaxTrades(e.target.value)} className="form-control" placeholder="Max Trades" />
                                        </div>
                                        <div className="fieldWrapper">
                                            <label>Auto Stop Loss</label>
                                            <ReactSwitch
                                            onChange={(e)=>{
                                                setAutoStopLoss(!autoStopLoss)
                                                setDisableStopLoss(!disableStopLoss)
                                                setStopLoss('')
                                            }}
                                            checked={autoStopLoss}
                                            className="react-switch"
                                            height={16}
                                            width={32}
                                            onColor='#8b3d16'
                                            onHandleColor='#f8610e'
                                            />
                                            <span className={`pointer text-${autoStopLoss ? "green" : "red"}`}>{autoStopLoss ? "Yes" : "No"}</span>
                                        </div>

                                        <div className="fieldWrapper">
                                            <label>Auto Position</label>
                                            <ReactSwitch
                                            onChange={(e)=>setAutoPosition(!autoPosition)}
                                            checked={autoPosition}
                                            className="react-switch"
                                            height={16}
                                            width={32}
                                            onColor='#8b3d16'
                                            onHandleColor='#f8610e'
                                            />
                                            <span className={`pointer text-${autoPosition ? "green" : "red"}`}>{autoPosition ? "Yes" : "No"}</span>
                                        </div>
                                    </div>

                                    <div className="buttonGroup">
                                        <button type="submit" className="button orange" onClick={() => addUserBrokerEpic()}>Submit</button>
                                    </div>
                                </div>
                                
                            </div>
                        </Modal.Body>
                        </Modal>
        </div>
    );
}

export default TradeTable;