import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import GlobalStyles from '@mui/material/GlobalStyles'
import PersonIcon from '@mui/icons-material/Person'
import Logo from '../Logo/Logo'
import Footer from '../footer'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { Window } from '@mui/icons-material'

export default function MainPricingDashboard(props) {
  const navigate = useNavigate();
  function handleClick()
  {
    localStorage.setItem(
      'isSkipped',
      true
    )
    navigate('../')
  }
  return (
    <React.Fragment>
      <Grid
        sx={{
          height: { md: '110vh', sm: '200vh', xs: '270vh' },
          backgroundColor: '#0f0f11',
        }}
      >
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }}
        />
        <CssBaseline />
        <Box sx={{ flexGrow: 1, backgroundColor: '#0f0f11' }}>
          <AppBar position="static" sx={{ backgroundColor: '#0f0f11' }}>
            <Toolbar>
              <Logo />
              {/* <Button color="inherit">Login</Button> */}
              <Button
                          type="button"
                          onClick={(e)=>handleClick()}
                          // variant="contained"
                          fullWidth
                          style={{
                            background: '#ee6535',
                            border: 'none',
                            marginRight: '12px',
                            textDecoration: 'none',
                            fontSize: '12px',
                            height: '40px',
                            borderRadius: '7px',
                          }}
                          sx={{
                            mt: 1,
                            mb: 2,
                            backgroundColor:'rgb(166,70,37)',
                            color: 'white',
                            border:'rgb(166,70,37)',
                            // fontSize: 12,
                            textDecoration: 'none',
                          }}
                        >
                          Skip
                        </Button>
              <PersonIcon
                fontSize="large"
                sx={{
                  fontSize: 40,
                  border: '2px solid #ff6838',
                  borderRadius: '50%',
                  backgroundColor: '#ff6838',
                }}
              />
            </Toolbar>
          </AppBar>
        </Box>

        {props.children}
      </Grid>
      <Footer />
    </React.Fragment>
  )
}
