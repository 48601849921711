import React, {useState} from "react";
import Chart from "react-apexcharts";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
const state = {
    series: [{
        name: 'Volatility',
        data: [90, 102, 28, 51, 42, 109, 100]
    }, {
        name: 'Return',
        data: [11, 32, 90, 32, 34, 82, 41]
    }],
    options: {
        colors: ['#ff0000', '#00e396'],
        dataLabels: {
          enabled: false
        },
        chart: {
            type: 'area',
            stacked: true,
            toolbar: {
              show: false
            }
        },
        grid: {
            show: true,
            borderColor: '#483a31',
            strokeDashArray: 0,
            position: 'back',
            xaxis: {
                lines: {
                    show: false
                }
            },   
            yaxis: {
                lines: {
                    show: true
                }
            },  
            row: {
                colors: undefined,
                opacity: 0.5
            },  
            column: {
                colors: undefined,
                opacity: 0.5
            },  
            // padding: {
            //     top: 0,
            //     right: 0,
            //     bottom: 0,
            //     left: 0
            // },  
        },        
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'days',
          categories: ["0", "5", "10", "15", "20", "25", "30"],
          labels: {
                // formatter: function (val) {
                //     return val + "D"
                // },
                style: {
                    colors: '#a1a1a1'
                }
            },
            title: {
                text: 'Days',
                show: false,
                style: {
                    color: '#a1a1a1',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    cssClass: 'apexcharts-xaxis-title',
                },
            },
        },
        yaxis: {
            type: 'percentage',
            categories: ["-100", "-50", "-25", "0", "25", "50", "100"],
            
            labels: {
                show: true,
                align: 'right',
                formatter: function (val) {
                    return val + "%"
                },
                style: {
                    colors: ['#a1a1a1']
                }
            },
            title: {
                text: '% Return',
                rotate: -90,
                offsetX: 0,
                offsetY: 0,
                style: {
                    color: '#f15200',
                    fontSize: '12px',
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-title',
                },
            },
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetX: 0,
            offsetY: 20,
            fontSize: '10px',
            fontWeight: 400,
            labels: {
              colors: ['#fff'],
            },
            markers: {
              width: 20,
              height: 6,
              radius: 6,
            }
        }
    }, 
};

const options = {

    title: {
        text: 'Trade Revenue'
    },

    subtitle: {
        text: 'Source: <a href="https://www.udecode.net" target="_blank">DEVELOPER</a>'
    },

    yAxis: {
        title: {
            text: 'Amount'
        }
    },

    xAxis: {
        accessibility: {
            rangeDescription: 'Range: 2010 to 2020'
        }
    },

    legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle'
    },

    plotOptions: {
        series: {
            label: {
                connectorAllowed: false
            },
            pointStart: 2010
        }
    },

    series: [{
        name: 'Installation & Developers',
        data: [43934, 48656, 65165, 81827, 112143, 142383,
            171533, 165174, 155157, 161454, 154610]
    }, {
        name: 'Manufacturing',
        data: [24916, 37941, 29742, 29851, 32490, 30282,
            38121, 36885, 33726, 34243, 31050]
    }, {
        name: 'Sales & Distribution',
        data: [11744, 30000, 16005, 19771, 20185, 24377,
            32147, 30912, 29243, 29213, 25663]
    }, {
        name: 'Operations & Maintenance',
        data: [null, null, null, null, null, null, null,
            null, 11164, 11218, 10077]
    }, {
        name: 'Other',
        data: [21908, 5548, 8105, 11248, 8989, 11816, 18274,
            17300, 13053, 11906, 10073]
    }],
    chart: {
        width: 450,
        backgroundColor: '#333'
    },
    responsive: {
        rules: [{
            condition: {
                maxWidth: 400
            },
            chartOptions: {
                legend: {
                    layout: 'horizontal',
                    align: 'center',
                    verticalAlign: 'bottom'
                }
            }
        }]
    }

}

  
function TradeReturn() {
    return (
        <div className="blockDivergent radialDivergent">
            {/* <HighchartsReact
                highcharts={Highcharts}
                options={options}
            /> */}
            <Chart options={state.options} series={state.series} type="area" height={240} width={460} />
        </div>
    );
}

export default TradeReturn;