import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";
import { useSelector } from "react-redux";
import { getIdToken } from "utils";
import { getConfig } from "config/app-config"; 
import LineChart from "./LineChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      align: "end",
      labels: {
        color: "rgb(255,255,255)",
        boxWidth: 10,
        boxHeight: 6,
      },
    },
    title: {
      display: true,
      text: "STRATEGY PERFORMANCE",
      align: "start",
      color: "#fff",
      padding: {
        bottom: -15,
      },
    },
  },
  scales: {
    ticks: {
      source:'auto',
    },
    y: {
      title: {
        display: true,
        text: 'PROFIT',
        color: "#fff"
      },
      type: 'linear',
      display: true,
      position: 'left',
      labelString: 'probability'
    },
    y1: {
      title: {
        display: true,
        text: 'RETURN',
        color: "#fff"
      },
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

export const responsiveOptions = {
  responsive: true,
  aspectRatio: 1.5,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: true,
      align: "center",
      labels: {
        color: "rgb(255,255,255)",
        boxWidth: 10,
        boxHeight: 6,
      },
    },
    title: {
      display: false,
      text: "STRATEGY PERFORMANCE",
      align: "start",
      color: "#fff",
      padding: {
        bottom: -15,
      },
    },
  },
  scales: {
    y: {
      title: {
        display: true,
        text: 'PROFIT',
        color: "#fff"
      },
      type: 'linear',
      display: true,
      position: 'left',
      labelString: 'probability'
    },
    y1: {
      title: {
        display: true,
        text: 'RETURN',
        color: "#fff"
      },
      type: 'linear',
      display: true,
      position: 'right',
      grid: {
        drawOnChartArea: false,
      },
    },
  },
};

//const labels = ["January", "February", "March", "April", "May", "June", "July"];

// export const data = {
//   //labels,
//   datasets: [
//     {
//       label: "Profit",
//       data: [],//labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: "#f76400",
//       fillColor: "#f76400",
//       backgroundColor: "#f76400",
//     },
//     {
//       label: "Cumulative Profit",
//       data: [],//labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: "#6f51ff",
//       fillColor: "#6f51ff",
//       backgroundColor: "#6f51ff",
//     },
//     {
//       label: "Return %",
//       data: [], //labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: "#e39f1b",
//       fillColor: "#e39f1b",
//       backgroundColor: "#e39f1b",
//     },
//     {
//       label: "Cumulative Return %",
//       data: [], //labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
//       borderColor: "#ff0000",
//       fillColor: "#ff0000",
//       backgroundColor: "#ff0000",
//     },
//   ],
// };

//console.log('data SETS ', data)

function StrategyChart() {
  const authInfo = useSelector(selector => selector.auth);
  const [chartData, setChartData] = useState();
  const [finalData, setFinalData] = useState();
  const [graphLabel, setGraphLabel] = useState();
  const brokerSlice = useSelector(selector => selector.brokerSlice)
  const [data, setData] = useState({
    //labels : [],
    datasets: [
      {
        name: "Profit",
        type : "line",
        data: [],//labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        color : "#f76400",
        legendColor : "#f76400",
        marker: {
          symbol: 'square'
        }
        // borderColor: "#f76400",
        // fillColor: "#f76400",
        // backgroundColor: "#f76400",
      },
      {
        name: "Cumulative Profit",
        type : "line",
        data: [],//labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        color : "#6f51ff",
        marker: {
          symbol: 'square'
        }
        // borderColor: "#6f51ff",
        // fillColor: "#6f51ff",
        // backgroundColor: "#6f51ff",
      },
      {
        name: "Return",
        type : "line",
        data: [], //labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        color : "#e39f1b",
        marker: {
          symbol: 'square'
        }
        // borderColor: "#e39f1b",
        // fillColor: "#e39f1b",
        // backgroundColor: "#e39f1b",
      },
      {
        name: "Cumulative Return %",
        type : "line",
        data: [], //labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        color : "#ff0000",
        marker: {
          symbol: 'square'
        }
        // borderColor: "#ff0000",
        // fillColor: "#ff0000",
        // backgroundColor: "#ff0000",
      },
    ],
  });

  useEffect(() => {
    // console.log('Just changed ', chartData)
    let obj = [{
      cumulative_profit : [],
      profit : [],
      performance_return : []
    }]
    let objLabel = [];
    if(chartData?.length){
      chartData?.map(graphVal => {
        if(graphVal.pl_daily_percent){
          objLabel.push(graphVal.date)
          let date = new Date(graphVal.date)
          obj[0].cumulative_profit.push([ date.getTime(), graphVal.pl_daily_percent])
        }
        if(graphVal.pl){
          objLabel.push(graphVal.date)
          let date = new Date(graphVal.date)
          obj[0].profit.push([ date.getTime() ,  graphVal.pl ])
        }
        if(graphVal.pl_cum_percent){
          objLabel.push(graphVal.date)
          let date = new Date(graphVal.date)
          obj[0].performance_return.push([ date.getTime(),  graphVal.pl_cum_percent ])
        }
      })
    }
    // console.log('Obj is here ', obj)
    setGraphLabel(objLabel)
    setFinalData(obj)
  }, [chartData])

  useEffect(() => {
    if(typeof finalData !== 'undefined'){
      data.datasets.forEach(element => {
        if(element.name === 'Profit'){
          element.data = finalData[0].profit
        }
        // console.log('element.name ', element.name)
        if(element.name === 'Cumulative Profit'){
          // console.log('I am inside cumulative', finalData[0].cumulative_profit)
          element.data = finalData[0].cumulative_profit

        }

        if(element.name === 'Return'){
          element.data = finalData[0].performance_return
          element.yAxis = 1
        }
      });
      
      setData(data)
    }
  }, [ finalData ])

  const fetchGraphData = async (selectedBroker=null) => {
    setChartData([]);
    var userData=JSON.parse(localStorage.getItem('credentials'));
    setChartData([])
    await fetch(`${getConfig().API_URL}/strategies/performance?start_date=2022-12-04T00:00:00&end_date=2023-01-04T23:59:59&epic=null&broker_id=${selectedBroker ? selectedBroker : authInfo?.user.id}&period=D&public_id=${userData.public_id}`, {
      method: 'GET',
      headers: {
        Authorization:getIdToken()
      }
    })
    .then(resp => resp.json())
    .then(respData => setChartData(respData?.series))
  }
  
  // useEffect(() => {
  //   fetchGraphData(brokerSlice.selectedBroker)
  // }, [brokerSlice.selectedBroker])

  const [winWidth, setWinWidth] = useState(0);

  useEffect(() => {
    fetchGraphData();
    const checkWindowWidth = () => {
      const newWidth = window.innerWidth;
      setWinWidth(newWidth);
    }
    window.addEventListener("load", checkWindowWidth);
    window.addEventListener("resize", checkWindowWidth);

    return () => {
      window.addEventListener("load", checkWindowWidth);
      window.removeEventListener("resize", checkWindowWidth)
    }
  }, [])
  return (
    <div className="strategy-chart">
      {/* {winWidth > 700 ?
       (<Line options={options} data={data} />) : 
       (<div className="strategy-chart-responsive"><h6>STRATEGY PERFORMANCE</h6>
        <Line options={responsiveOptions} data={data} />
       </div>
      )} */}
      <LineChart dataSeries={data} />
    </div>
  );
}

export default StrategyChart;
