export function getConfig() {
  if (process.env.REACT_APP_PUBLIC_APP_ENV === 'prod') {
    return {
      API_URL: process.env.REACT_APP_PUBLIC_API_URL_PROD,
      API_URL_ITC:'http://dcapital-backend.itechnolabs.tech/api/v1'
    };
  }
  return {
    API_URL: process.env.REACT_APP_PUBLIC_API_URL_DEV,
    API_URL_ITC:'http://dcapital-backend.itechnolabs.tech/api/v1'
  };
}
