import { getConfig } from "config/app-config";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { getIdToken } from "utils";

function TransactionsStats() {
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [brokersData, setBrokersData] = useState([]);
    const [publicId, setPublicId] = useState('');
    const [options,setOptions]=useState([]);
    const [defaultBroker,setDefaultBroker]=useState({});
    const [defaultEpic,setDefaultEpic]=useState({});
    const [granularityOptions,setGranularityOptions]=useState([
      {
        value:'Y',
        label:'Year'
      },
      {
        value:'M',
        label:'Month'
      },
      {
        value:'D',
        label:'Day'
      },
      {
        value:'H',
        label:'Hour'
      },
      {
        value:'min',
        label:'Minute'
      }
    ]);
    const [defaultGranule,setDefaultGranule]=useState({
      
        value:'D',
        label:'Day'
      
    });

    const [epicOptions,setEpicOptions]=useState([]);
    function getBrokers()
    {
        var userData=JSON.parse(localStorage.getItem('credentials'));
        setPublicId(userData.public_id);
        fetch(`${getConfig().API_URL}/users/brokers/${userData.public_id}`, {
            method: 'GET',
            headers: {
              authorization:getIdToken()
            },
          }).then(response => response.json())
          .then(data => {
            setBrokersData(data);
            var opt=[];
            var i=0;
            data.forEach(element => {
              
              var obj={
                value:element.broker_id,
                label:element.broker.name
              }           
              if(++i==1)
              {
                  setDefaultBroker(obj);
              }
                opt.push(obj);
          });
          setOptions(opt);
          });
    }
    function getEpics()
    {
        var userData=JSON.parse(localStorage.getItem('credentials'));
        setPublicId(userData.public_id);
        fetch(`${getConfig().API_URL}/epicType/epics?broker_id=${defaultBroker.value}`, {
            method: 'GET',
            headers: {
              authorization:getIdToken()
            },
          }).then(response => response.json())
          .then(data => {
            var opt=[];
            data.forEach(element => {
              var obj={
                value:element.id,
                label:element.name
              }           
                opt.push(obj);
          });
          setEpicOptions(opt);
          });
    }

    useEffect(()=>getBrokers(),[]);
    useEffect(()=>getEpics(),[defaultBroker]);
    return (
      <>  </>
        // <div className='divTransaction'>
        //     <div className='row align-items-center'>
        //         <div className='col-12 col-lg-4'>
        //           <div class="fieldWrapper">
        //             <label>Start Date</label>
        //             <DatePicker selected={startDate} onChange={(date:Date) => setStartDate(date)} />
        //           </div>
        //         </div>
        //         <div className='col-12 col-lg-4'>
        //           <div class="fieldWrapper">
        //             <label>End Date</label>
        //             <DatePicker selected={startDate} onChange={(date:Date) => setEndDate(date)} />
        //           </div>
        //         </div>
        //         <div className='col-12 col-lg-4'>
        //           <div class="fieldWrapper">
        //             <label>Broker</label>
        //             <Select className="selectBox" value={defaultBroker} options={options}  onChange={(element)=>setDefaultBroker(element)} />
        //           </div>
        //         </div>
        //         <div className='col-12 col-lg-4'>
        //           <div class="fieldWrapper">
        //             <label>Epic</label>
        //             <Select className="selectBox" options={epicOptions} onChange={(element)=>setDefaultEpic(element)}  />
        //           </div>
        //         </div>
        //         <div className='col-12 col-lg-4'>
        //           <div class="fieldWrapper">
        //             <label>Granularity</label>
        //             <Select className="selectBox" value={defaultGranule} options={granularityOptions} onChange={(element)=>setDefaultGranule(element)}  />
        //           </div>
        //         </div>
        //         <div className='col-12 col-lg-4'>
        //           <div class="fieldWrapper">
        //             <label>Size</label>
        //             <input type='text' class='form-control' placeholder='-150000.00' value='-150000.00' />
        //           </div>
        //         </div>
        //         <div className='col-12 col-lg-4'>
        //           <div class="fieldWrapper">
        //             <label>Profit</label>
        //             <input type='text' class='form-control green' placeholder='681.97' value='681.97' />
        //           </div>
        //         </div>
        //         <div className='col-12 col-lg-4'>
        //           <div class="fieldWrapper">
        //             <label>Comulative</label>
        //             <input type='text' class='form-control' placeholder='131722.29' value='131722.29' />
        //           </div>
        //         </div>
        //         <div className='col-12 col-lg-4'>
        //           {/* <h3>LOREM IPSUM</h3> 
        //           <p>IS DUMMY TEXT</p> */}
        //         </div>
        //     </div>
        // </div>
    )
}

export default TransactionsStats