import React from "react";
import NumberFormat from 'react-number-format';
import {
    useUpdateGeneralMutation,
  } from "services/riskManagement";
  import { toast } from 'react-toastify';

const NumberFielder = (props) => {
  const notify = text => toast(text);
    const { name, defaultvalue, whichChange, id, option_value, option_value_medium, option_value_high, setting_id } = props
    const [updatePost, { isLoadingPost }] = useUpdateGeneralMutation();

    const MAX_VAL = 600;
    const withValueLimit = ({ floatValue }) => floatValue <= MAX_VAL;
    const handleOptionChange = async (id, optionValue, optionValueMedium, optionValueHigh, settingId) => {
        let obj = {
          id,
          option_value: optionValue,
          option_value_medium : optionValueMedium,
          option_value_high : optionValueHigh,
          setting_id: settingId,
        };
        const response = await updatePost(obj);
        if(response?.data?.status){
          notify(response?.data?.message)
        }
      };

      const prePareForCall = (e) => {
        if(whichChange === 'option_value'){
            handleOptionChange(id, e, option_value_medium, option_value_high, setting_id)
        }

        if(whichChange === 'option_value_medium'){
            handleOptionChange(id, option_value, e, option_value_high, setting_id)
        }

        if(whichChange === 'option_value_high'){
            handleOptionChange(id, option_value, option_value_medium, e, setting_id)
        }
      }

    return (
        <NumberFormat className='w30' name='stoploss' defaultValue={`${defaultvalue}`} onValueChange={e => prePareForCall(e.value)} displayType={'input'} isAllowed={withValueLimit}  />
    );

};

export default NumberFielder;