import React, { useEffect, useState } from 'react';
import { getIdToken } from "utils";
import {getConfig } from '../../../config/app-config';
import { useSelector } from "react-redux";

function HomeSectionTwo () {
  const authInfo = useSelector(selector => selector.auth);
  const brokerSlice = useSelector(selector => selector.brokerSlice)
  const [brokerId,setBrokerId]=useState('');
  const [metrices,setMetrices]=useState({});
  const [accounts,setAccounts]=useState({});

  function getBrokers()
    {
        fetch(`${getConfig().API_URL}/users/brokers/${authInfo.user.public_id}`, {
            method: 'GET',
            headers: {
              authorization:getIdToken()
            },
          }).then(response => response.json())
          .then(data => {
           if(data.length>0)
           {
            data.map(broker => {
              if (broker.id === authInfo.user.default_user_broker_id) {
                setBrokerId(broker.broker_id);
              }
            })
           }
            
          });
    }

  function getKeyMetrices(selectedBroker = null)
    {
      if(brokerId && authInfo.user.public_id)
      {
        fetch(`${getConfig().API_URL}/accounts/?broker_id=${selectedBroker ? selectedBroker : brokerId}&public_id=${authInfo.user.public_id}`, {
          method: 'GET',
          headers: {
            authorization:getIdToken()
          },
        }).then(response => response.json())
        .then(data => {
          setMetrices(data);
        }); 
      }
    }


  function getAccounts(selectedBroker = null)
    {
      if(brokerId && authInfo.user.public_id)
      {
        fetch(`${getConfig().API_URL}/accounts/all?broker_id=${selectedBroker ? selectedBroker : brokerId}&public_id=${authInfo.user.public_id}`, {
          method: 'GET',
          headers: {
            authorization:getIdToken()
          },
        }).then(response => response.json())
        .then(data => {
          setAccounts(data);
        }); 
      }
    }

  function switchAccount (event) 
    {    
      fetch(`${getConfig().API_URL}/accounts/switch`, {
        method: 'POST',
        body: JSON.stringify({
          public_id: authInfo.user.public_id,
          broker_id: brokerId,
          account_id: event.target.value
        }),
        headers: {
          authorization:getIdToken(),
          'content-type': 'application/json'
        },
      }).then(response => response.json())
      .then(data => {
        getKeyMetrices();
        getAccounts();
      }); 
    }

  useEffect(() => {
    getBrokers()
    getKeyMetrices()
    getAccounts()
  }, [brokerId, authInfo.user.public_id]);

  useEffect(() => {
    getKeyMetrices(brokerSlice.selectedBroker)
    getAccounts(brokerSlice.selectedBroker)
  }, [brokerSlice.selectedBroker])
  return (
    <div className="row rowDivergent">
        <div className="col-12">
          <ul className="statusAccount">
            <li className='account_li'>
              <span>Account</span>
              {/* <strong>{metrices.accountName}</strong> */}
              {0 < accounts.length ? 
              <select className='form-control homeselectbox' onChange={(event) => switchAccount(event)}>
                {accounts.map((result, key) => {
                    return <option value={result.accountId} selected={metrices.accountId === result.accountId ? 'selected' : ''}>{result.accountName+'('+result.accountId+')'}</option>
                })}
              </select> : ''}
              
            </li>
            <li>
              <span>Currency</span>
              <strong>{metrices.currency}</strong>
            </li>
            <li>
              <span>Balance</span>
              <strong>{metrices.balance}</strong>
            </li>
            <li>
              <span>Margin Used %</span>
              <strong>--</strong>
            </li>
            <li className={ metrices.profitLoss > 0 ? 'active' : null}>
              <span>Unrealised P/L</span>
              <strong style={ { color: metrices.profitLoss > 0 ? 'green' : 'red' } }>{metrices.profitLoss}</strong>
            </li>
            <li>
              <span>Margin Available %</span>
              <strong>--</strong>
            </li>
            <li>
              <span>No. Winning Trades</span>
              <strong>L-1 S-2</strong>
            </li>
            <li>
              <span>No. Losing Trades</span>
              <strong>L-0 S-0</strong>
            </li>
          </ul>
          
        </div>
      </div>
  )
}

export default HomeSectionTwo;