import React, { useState, useEffect } from "react";
import "./style.css";
import { useGetRulesQuery, useUpdateTradeRulesMutation } from "services/riskManagement";
import { ToastContainer, toast } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';

function TradingRules(){
  const { data, error, isLoading } = useGetRulesQuery({}, { refetchOnMountOrArgChange: true });
  const [updateRule, { isLoadingPost }] = useUpdateTradeRulesMutation();
  const notify = text => toast(text);
  useEffect(() => {
    setStateT(prev => ({
      ...prev,
      completedTasks : data?.active_rules,
      todos : data?.inactive_rules
    }))
  }, [data])

  const [stateT, setStateT] = useState({
    todos: [],
    completedTasks: [],
    draggedTask: {},
  });

  const onDropTodo = (event) => {
    const { completedTasks, draggedTask, todos } = stateT;

    // Ignore if task is dropped in the same box
    var found = todos.filter((todo) => todo.id === draggedTask.id);
    if (found.length > 0) {
      return;
    }

    setStateT(prev => ({
        ...prev,
      todos: [...todos, draggedTask],
      completedTasks: completedTasks.filter(
        (task) => task.id !== draggedTask.id
      ),
      draggedTask: {},
    }));
  };

  const onDragOverTodo = (event) => {
    // console.log("onDragOverTodo ", stateT)
    event.preventDefault();
  };

  const dragStartTodo = (event) => {
    // console.log("dragStartTodo ", stateT)
    document.getElementById(event.target.id).classList.add("on-drag");
  };

  const dragEndTodo = (event) => {
    // console.log("dragEndTodo ", stateT)
    document.getElementById(event.target.id).classList.remove("on-drag");
  };

  const onDragTodo = (event, todo) => {
    // console.log("onDragTodo ", stateT)
    event.preventDefault();
    setStateT(prev => ({
        ...prev,
      draggedTask: todo,
    }));
  };

  const onDropCompleted = (event) => {
    // console.log("onDropCompleted ", stateT)
    const { completedTasks, draggedTask, todos } = stateT;

    // Ignore if task is dropped in the same box
    var found = completedTasks.filter(
      (task) => task.id === draggedTask.id
    );

    if (found.length > 0) {
      return;
    }

    setStateT(prev => ({
        ...prev,
      completedTasks: [...completedTasks, draggedTask],
      todos: todos.filter((task) => task.id !== draggedTask.id),
      draggedTask: {},
    }));
  };

  const onDragOverCompleted = (event) => {
    // console.log("onDragOverCompleted ", stateT)
    event.preventDefault();
  };

  const onDragCompleted = (event, todo) => {
    // console.log("onDragCompleted ", stateT)
    event.preventDefault();
    setStateT(prev => ({
        ...prev,
      draggedTask: todo,
    }));
  };

  const handleSave = async () => {
    let obj = {
      active_rules : stateT.completedTasks,
      inactive_rules : stateT.todos
    }
    let response = await updateRule(obj);
    if(response?.data?.status){
      notify(response?.data?.message)
    }
  }

  return(
    <>
    <ToastContainer />
  
    <div className="App app_custom">
    
      <div
        className="todos"
        onDrop={(event) => onDropTodo(event)}
        onDragOver={(event) => onDragOverTodo(event)}
      >
        <div className="headerAll headeraall_custom">All</div>
        <div>
          {stateT?.todos?.map((todo) => (
            <div
              className="itemAll itemall_custom"
              id={todo.id}
              key={todo.id}
              draggable
              onDragStart={(e) => dragStartTodo(e)}
              onDragEnd={(e) => dragEndTodo(e)}
              onDrag={(event) => onDragTodo(event, todo)}
            >
              {todo.rule}
            </div>
          ))}
        </div>
      </div>
      <div
        onDrop={(event) => onDropCompleted(event)}
        onDragOver={(event) => onDragOverCompleted(event)}
        className="done"
      >
        <div className="headerActive headeraall_custom">Active</div>
        {stateT?.completedTasks?.map((task, index) => (
          <div
            className="itemActive itemall_custom"
            key={task.id}
            id={task.id}
            draggable
            onDragStart={(e) => dragStartTodo(e)}
            onDragEnd={(e) => dragEndTodo(e)}
            onDrag={(event) => onDragCompleted(event, task)}
          >
            {task.rule}
          </div>
        ))}
      </div>
    </div>
    <button className="btn btn-success save-custom" onClick={ () => handleSave()}>Save</button>
    </>
  );
}

export default TradingRules