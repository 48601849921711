import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import RiskManagementTable from '../RiskManagementTable';
import TradeTable from '../TradeTable';
import TradeTimingTable from '../TradeTimingTable';
import PredictionTable from '../PredictionTable';
import SentimentTable from '../SentimentTable';
import AccountTable from '../AccountTable';
import BackboneStrategyTable from '../BackboneStrategyTable';
import OptimisationTable from '../OptimisationTable';
import RSIEMAROCTable from '../RSIEMAROCTable';
import TradingRules from "./TradingRules";

function ParameterSet() {
    const [tabIndex, setTabIndex] = useState(0);
    return (
    <Tabs className="generalTab" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
            <Tab>Risk Management</Tab>
            <Tab>Trade</Tab>
            <Tab>Trade Timing</Tab>
            <Tab>Prediction</Tab>
            <Tab>Sentiment</Tab>
            <Tab>Account</Tab>
            <Tab>Backbone Strategy</Tab>
            <Tab>Optimisation</Tab>
            <Tab>Momentum</Tab>
            <Tab>Trading Rules</Tab>
        </TabList>
        <TabPanel>
            <RiskManagementTable descriptionType="Risk Management" />
        </TabPanel>
        <TabPanel>
            {/* <TradeTable /> */}
            <RiskManagementTable descriptionType="Trade" />
        </TabPanel>
        <TabPanel>
            {/* <TradeTimingTable /> */}
            <RiskManagementTable descriptionType="Trade Timing" />
        </TabPanel>
        <TabPanel>
            <RiskManagementTable descriptionType="Prediction" />
            {/* <PredictionTable /> */}
        </TabPanel>
        <TabPanel>
            <RiskManagementTable descriptionType="Sentiment " />
            {/* <SentimentTable /> */}
        </TabPanel>
        <TabPanel>
            <RiskManagementTable descriptionType="Account " />
            {/* <AccountTable /> */}
        </TabPanel>
        <TabPanel>
            <RiskManagementTable descriptionType="Backbone Strategy" />
            {/* <BackboneStrategyTable /> */}
        </TabPanel>
        <TabPanel>
            <RiskManagementTable descriptionType="Optimisation" />
            {/* <OptimisationTable /> */}
        </TabPanel>
        <TabPanel>
            <RSIEMAROCTable />
        </TabPanel>
        <TabPanel>
            <TradingRules />
        </TabPanel>
    </Tabs> 
    )
}

export default ParameterSet