import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { getIdToken } from "utils";
import {getConfig } from '../../../config/app-config';
import { useSelector } from "react-redux";
var moment = require('moment'); 
function PositionsRecord() {
    const authInfo = useSelector(selector => selector.auth);
    const [tabIndex, setTabIndex] = useState(0);
    const [tabsDataOpen,setTabsDataOpen]=useState([]);
    const [tabsDataClose,setTabsDataClose]=useState([]);
    const [brokerId,setBrokerId]=useState('');
    const [publicId,setPublicId]=useState('');


    function getBrokers()
    {
        var userData=JSON.parse(localStorage.getItem('credentials'));
        setPublicId(userData.public_id);
        fetch(`${getConfig().API_URL}/users/brokers/${userData.public_id}`, {
            method: 'GET',
            headers: {
              authorization:getIdToken()
            },
          }).then(response => response.json())
          .then(data => {
           if(data.length>0)
           {
                setBrokerId(data[0].broker_id);
           }
            
          });
    }

    useEffect(()=>getBrokers(),[]);
    useEffect(()=>getTabsData(),[brokerId,publicId]);
   
    function getTabsData()
    {
        if(!brokerId || !publicId)
        {
            return;
        }
        console.log("iam in");
        fetch(`${getConfig().API_URL}/strategies/lastPositions?broker_id=${authInfo?.user.id}&public_id=${publicId}`, {
            method: 'GET',
            headers: {
              authorization: getIdToken()
            },
          }).then(response => response.json())
          .then(data => {
            var openData=[];
                var closeData=[];
            data.data.forEach(element => {
                
                if(element.state=='OPEN')
                {
                    openData.push(element);
                }
                else
                {
                    closeData.push(element);
                }
            });
            setTabsDataOpen(openData);
            setTabsDataClose(closeData);
          });
    }



    return (

<div className="divergence-table table-responsive">
        
    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
            <Tab>Open</Tab>
            <Tab>Closed</Tab>
        </TabList>
        <TabPanel>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Status</th>
                        <th scope="col" className="mw-120">Epic</th>
                        <th scope="col" className="mw-160">Open Time</th>
                        <th scope="col" className="mw-160">Close Time</th>
                        <th scope="col">Duration</th>
                        <th scope="col" className="mw-120">Entry Price</th>
                        <th scope="col" className="mw-120">Exit Price</th>
                        <th scope="col">SL</th>
                        <th scope="col">TP</th>
                        <th scope="col" className="mw-120">Buy Filled</th>
                        <th scope="col" className="mw-120">Sell Filled</th>
                        <th scope="col" className="mw-120">Profit</th>
                        {/* <th scope="col" className="mw-120">Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {

                         tabsDataOpen?.map((element)=>
<tr>
                        <td class="green">{element.state}</td>
                        <td>{element.epic}</td>
                        <td>{moment(element.openTime).format('YYYY/MM/DD hh:mm:ss A')}</td>
                        <td>{element.closeTime?moment(element.closeTime).format('YYYY/MM/DD hh:mm:ss A'):'-'}</td>
                        <td>{element.duration??'-'}</td>
                        <td class="green">{element.entryPrice}</td>
                        <td class="red">{element.exitPrice??'-'}</td>
                        <td>{element.sl??'-'}</td>
                        <td>{element.tp??'-'}</td>
                        <td>{element.size}</td>
                        <td>{element.size}</td>
                        <td class={element.pl>0?'green':'red'}>{element.pl}</td>
                        {/* <td class="action">
                            <a href="#" style={{color:'none'}}><i class="fas fa-times"></i></a>
                        </td> */}
                    </tr>
                        )
                    }
                    
                    
                   
                </tbody>
            </table>
        </TabPanel>
        <TabPanel>
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Status</th>
                        <th scope="col" className="mw-120">Epic</th>
                        <th scope="col" className="mw-160">Open Time</th>
                        <th scope="col" className="mw-160">Close Time</th>
                        <th scope="col">Duration</th>
                        <th scope="col" className="mw-120">Entry Price</th>
                        <th scope="col" className="mw-120">Exit Price</th>
                        <th scope="col">SL</th>
                        <th scope="col">TP</th>
                        <th scope="col" className="mw-120">Buy Filled</th>
                        <th scope="col" className="mw-120">Sell Filled</th>
                        <th scope="col" className="mw-120">Profit</th>
                        {/* <th scope="col" className="mw-120">Action</th> */}
                    </tr>
                </thead>
                <tbody>
                    {

                         tabsDataClose?.map((element)=>
<tr>
                        <td class="red">{element.state}</td>
                        <td>{element.epic}</td>
                        <td>{moment(element.openTime).format('YYYY/MM/DD hh:mm:ss A')}</td>
                        <td>{element.closeTime?moment(element.closeTime).format('YYYY/MM/DD hh:mm:ss A'):'-'}</td>
                        <td>{element.duration??'-'}</td>
                        <td class="green">{element.entryPrice}</td>
                        <td class="red">{element.exitPrice??'-'}</td>
                        <td>{element.sl??'-'}</td>
                        <td>{element.tp??'-'}</td>
                        <td>{element.size}</td>
                        <td>{element.size}</td>
                        <td class={element.pl>0?'green':'red'}>{element.pl}</td>
                        {/* <td class="action">
                            <a href="#" style={{color:'none'}}><i class="fas fa-times"></i></a>
                        </td> */}
                    </tr>
                        )
                    }
                    
                    
                   
                </tbody>
            </table>
        </TabPanel>
    </Tabs> 

</div>
    
    )
}

export default PositionsRecord