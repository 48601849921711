import React, { useEffect } from "react";
import ToggleYesNo from "components/common/ToggleYesNo";
import NumberFielder from "components/common/numberfielder";
import { ToastContainer, toast } from 'react-toastify';
import {
  useGetRiskManagementQuery,
  useUpdateGeneralMutation,
} from "services/riskManagement";
import Select from "react-select";

function RiskManagementTable(props) {
  const notify = text => toast(text);
  const { descriptionType } = props;
  const { data, error, isLoading } = useGetRiskManagementQuery();
  const [updatePost, { isLoadingPost }] = useUpdateGeneralMutation();
  // useEffect(() => {
  //   // console.log("data HERE ", JSON.stringify(data))
  // }, [data]);
  const getOptions = (option) => {
    if (option) {
      let arr = [];
      JSON.parse(option)?.options?.map((val) => {
        let obj = {
          label: val,
          value: val,
        };
        arr.push(obj);
      });
      return arr;
    }
  };

  const handleOptionChange = async (id, optionValue, optionValueMedium, optionValueHigh, settingId) => {
    let obj = {
      id,
      option_value: optionValue,
      option_value_medium : optionValueMedium,
      option_value_high : optionValueHigh,
      setting_id: settingId,
    };

    const response =  await updatePost(obj);
    if(response?.data?.status){
      notify(response?.data?.message)
    }

  };

  return (
    <div className="divergence-table table-responsive mb-0">
      <ToastContainer />
      <table class="table">
        <thead>
          <tr>
            <th scope="col" className="mw-160">
              Parameter
            </th>
            <th scope="col" className="mw-120">
              Conservative Strategy
            </th>
            <th scope="col" className="mw-120">
              Moderate Strategy
            </th>
            <th scope="col" className="mw-120">
              Aggressive Strategy
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((val, index) => {
            return (
              <>
                {val?.setting?.option_description === descriptionType ? (
                  <tr>
                    <td>{val?.setting?.option_title}</td>
                    <td>
                      {val?.setting?.option_type === "select" ? (
                        val?.setting?.option_default === "True" ?
                        <ToggleYesNo 
                            defaultValue={val?.option_value} 
                            whichChange="option_value" 
                            option_value={val?.option_value}
                            option_value_medium={val?.option_value_medium}
                            option_value_high={val?.option_value_high}
                            setting_id={val?.setting?.id}
                            id={val?.id}
                        />
                        :
                        <Select
                          defaultValue={{
                            label: val?.option_value,
                            value: val?.option_value,
                          }}
                          className="selectBoxTable"
                          classNamePrefix="selectBoxStyle"
                          options={getOptions(val?.setting?.option_values)}
                          onChange={ (e) =>
                            handleOptionChange(
                              val?.id,
                              e.value,
                              val?.option_value_medium,
                              val?.option_value_high,
                              val?.setting?.id
                            )
                          }
                        />
                      ) : (
                        <NumberFielder 
                            defaultvalue={val?.option_value} 
                            whichChange="option_value" 
                            option_value={val?.option_value}
                            option_value_medium={val?.option_value_medium}
                            option_value_high={val?.option_value_high}
                            setting_id={val?.setting?.id}
                            id={val?.id}
                        />
                      )}
                    </td>
                    <td>
                      {val?.setting?.option_type === "select" ? (
                         val?.setting?.option_default === "True" ?
                         <ToggleYesNo 
                            defaultValue={val?.option_value_medium}
                            whichChange="option_value_medium" 
                            option_value={val?.option_value}
                            option_value_medium={val?.option_value_medium}
                            option_value_high={val?.option_value_high}
                            setting_id={val?.setting?.id}
                            id={val?.id}
                        />
                         :
                        <Select
                          defaultValue={{
                            label: val?.option_value_medium,
                            value: val?.option_value_medium,
                          }}
                          className="selectBoxTable"
                          classNamePrefix="selectBoxStyle"
                          options={getOptions(val?.setting?.option_values)}
                          onChange={ (e) =>
                            handleOptionChange(
                              val?.id,
                              val?.option_value,
                              e.value,
                              val?.option_value_high,
                              val?.setting?.id
                            )
                          }
                        />
                      ) : (
                        <NumberFielder
                          defaultvalue={val?.option_value_medium}
                          whichChange="option_value_medium" 
                          option_value={val?.option_value}
                          option_value_medium={val?.option_value_medium}
                          option_value_high={val?.option_value_high}
                          setting_id={val?.setting?.id}
                          id={val?.id}
                        />
                      )}
                    </td>
                    <td>
                      {val?.setting?.option_type === "select" ? (
                         val?.setting?.option_default === "True" ?
                         <ToggleYesNo 
                            defaultValue={val?.option_value_high} 
                            whichChange="option_value_high" 
                            option_value={val?.option_value}
                            option_value_medium={val?.option_value_medium}
                            option_value_high={val?.option_value_high}
                            setting_id={val?.setting?.id}
                            id={val?.id} 
                        />
                         :
                        <Select
                          defaultValue={{
                            label: val?.option_value_high,
                            value: val?.option_value_high,
                          }}
                          className="selectBoxTable"
                          classNamePrefix="selectBoxStyle"
                          options={getOptions(val?.setting?.option_values)}
                          onChange={ (e) =>
                            handleOptionChange(
                              val?.id,
                              val?.option_value,
                              val?.option_value_medium,
                              e.value,
                              val?.setting?.id
                            )
                          }
                        />
                      ) : (
                        <NumberFielder 
                            defaultvalue={val?.option_value_high}
                            whichChange="option_value_high" 
                            option_value={val?.option_value}
                            option_value_medium={val?.option_value_medium}
                            option_value_high={val?.option_value_high}
                            setting_id={val?.setting?.id}
                            id={val?.id} 
                        />
                      )}
                    </td>
                  </tr>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default RiskManagementTable;
