import React, { useEffect, useState } from 'react'
import { getIdToken } from "utils";
import {getConfig } from '../../../config/app-config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import Moment from 'moment';

var moment = require('moment'); 


function TransactionsRecord() {
  let d = new Date();
  d.setDate(d.getDate()-30);

    const [startDate, setStartDate] = useState(d);
    const [endDate, setEndDate] = useState(new Date());
    const [brokersData, setBrokersData] = useState([]);
    const [transactionsData, setTransactionsData] = useState([]);
    const [publicId, setPublicId] = useState('');
    const [options,setOptions]=useState([]);
    const [defaultBroker,setDefaultBroker]=useState({});
    const [defaultEpic,setDefaultEpic]=useState({});
    const [granularityOptions,setGranularityOptions]=useState([
      {
        value:'Y',
        label:'Year'
      },
      {
        value:'M',
        label:'Month'
      },
      {
        value:'D',
        label:'Day'
      },
      {
        value:'H',
        label:'Hour'
      },
      {
        value:'min',
        label:'Minute'
      }
    ]);
    const [defaultGranule,setDefaultGranule]=useState({
      
        value:'D',
        label:'Day'
      
    });

    const [epicOptions,setEpicOptions]=useState([]);
    function getBrokers()
    {
        var userData=JSON.parse(localStorage.getItem('credentials'));
        setPublicId(userData.public_id);
        fetch(`${getConfig().API_URL}/users/brokers/${userData.public_id}`, {
            method: 'GET',
            headers: {
              authorization:getIdToken()
            },
          }).then(response => response.json())
          .then(data => {

            setBrokersData(data);
            var opt=[];
            var i=0;
            data.forEach(element => {
              
              var obj={
                value:element.broker_id,
                label:element.broker.title
              }           
              if(++i==1)
              {
                  setDefaultBroker(obj);
              }
                opt.push(obj);
          });
          setOptions(opt);
          });
    }
    function getEpics()
    {
        var userData=JSON.parse(localStorage.getItem('credentials'));
        setPublicId(userData.public_id);
        console.log(defaultBroker.value, brokersData);

        let arr = [];
        brokersData.map((value, key) => {
          if (defaultBroker.value == value.broker_id) {
            value.user_broker_portfolio.map((epics, key) => {
              epics.portfolio.epics.map((epicss, key) => {
                arr.push({
                  value:epicss.id,
                  label:epicss.epic_name
                })
              })
            })
          }
        })
        setEpicOptions(arr);


        // fetch(`${getConfig().API_URL}/epicType/epics?broker_id=${defaultBroker.value}`, {
        //     method: 'GET',
        //     headers: {
        //       authorization:getIdToken()
        //     },
        //   }).then(response => response.json())
        //   .then(data => {
        //     var opt=[];
        //     data.forEach(element => {
        //       var obj={
        //         value:element.id,
        //         label:element.name
        //       }           
        //         opt.push(obj);
        //   });
        //   setEpicOptions(opt);
        //   });
    }

    useEffect(()=>getBrokers(),[]);
    useEffect(()=>getEpics(),[defaultBroker]);

    useEffect(()=>getTransactions(),
    [startDate,endDate,defaultBroker,defaultEpic,defaultGranule])
    
    
    const [transactions, setTransactions] = useState([]);
    
    
    function getTransactions()
    {
        if(publicId)
        {
            fetch(`${getConfig().API_URL}/strategies/performance?start_date=${Moment(startDate).format('YYYY-MM-DD')}T00:00:00&end_date=${Moment(endDate).format('YYYY-MM-DD')}T23:59:59&broker_id=${defaultBroker.value}&public_id=${publicId}&epic=${defaultEpic.value}&period=${defaultGranule.value}`, {
                method: 'GET',
                headers: {
                  authorization:getIdToken()
                },
              }).then(response => response.json())
              .then(data => {
                console.log(data, 'AAAA')
                setTransactions(data.data);
              });
        }
    }






const [currentPage, setCurrentPage] = useState(0);
const [arrayChunks, setArrayChunks] = useState([]);


useEffect(() => {

  let currentPage = 0;
  const chunkSize = 10;
  let arrayChunk = [];
  if (null != transactions) {
    for (let i = 0; i < transactions.length; i += chunkSize) {
        arrayChunk.push(transactions.slice(i, i + chunkSize))
    }
  
    console.log(arrayChunk, 'Arr chunk')
    setArrayChunks(arrayChunk)
  
    if ('undefined' !== typeof arrayChunk[currentPage]) {
      setTransactionsData(arrayChunk[currentPage])
    }
  }
  
}, [transactions])


function nextPage(event)
{
    if (currentPage == parseInt(arrayChunks.length) - 1) {
        return false;
    }
    let page = parseInt(currentPage) + 1
    setCurrentPage(page)
    setTransactionsData(arrayChunks[page])
}

function previousPage(event)
{
    if (currentPage <= 0) {
        return false;
    }
    let page = parseInt(currentPage) - 1
    setCurrentPage(page)
    setTransactionsData(arrayChunks[page])
}

const pages = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    return (<>
     <div className='divTransaction'>
            <div className='row align-items-center'>
                <div className='col-12 col-lg-4'>
                  <div class="fieldWrapper">
                    <label>Start Date</label>
                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  <div class="fieldWrapper">
                    <label>End Date</label>
                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  <div class="fieldWrapper">
                    <label>Broker</label>
                    <Select className="selectBox" value={defaultBroker} options={options}  onChange={(element)=>setDefaultBroker(element)} />
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  <div class="fieldWrapper">
                    <label>Epic</label>
                    <Select className="selectBox" options={epicOptions} onChange={(element)=>setDefaultEpic(element)}  />
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  <div class="fieldWrapper">
                    <label>Granularity</label>
                    <Select className="selectBox" value={defaultGranule} options={granularityOptions} onChange={(element)=>setDefaultGranule(element)}  />
                  </div>
                </div>
                {/* <div className='col-12 col-lg-4'>
                  <div class="fieldWrapper">
                    <label>Size</label>
                    <input type='text' class='form-control' placeholder='-150000.00' value='-150000.00' />
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  <div class="fieldWrapper">
                    <label>Profit</label>
                    <input type='text' class='form-control green' placeholder='681.97' value='681.97' />
                  </div>
                </div>
                <div className='col-12 col-lg-4'>
                  <div class="fieldWrapper">
                    <label>Comulative</label>
                    <input type='text' class='form-control' placeholder='131722.29' value='131722.29' />
                  </div>
                </div> */}
                <div className='col-12 col-lg-4'>
                  {/* <h3>LOREM IPSUM</h3> 
                  <p>IS DUMMY TEXT</p> */}
                </div>
            </div>
        </div>
   
        <div className="divergence-table table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Epic</th>
                        <th scope="col">Size</th>
                        <th scope="col">Profit</th>
                        <th scope="col">Cumulative</th>
                    </tr>
                </thead>
                <tbody>
                  {
                      transactionsData?.map((element)=>
                  
                    <tr>
                        <td>{Moment(element.date).format('YYYY-MM-DD')}</td>
                        <td>{element.epic}</td>
                        <td>{element.size}</td>
                        <td>{element.pl}</td>
                        <td>{element.cumulative}</td>
                    </tr>
                      )
}
                </tbody>
            </table>
            <ul className='pagination'>
                <li onClick={(event) => previousPage(event)}><i className="fas fa-angle-left"></i></li>
                {/* <li className='active'><span>{currentPage + 1}</span></li> */}
                {arrayChunks.map((value, key) => {
                  if (currentPage === 0 && key === 0) {
                    return <li className={currentPage === key ? 'active' : ''}><span>{key + 1}</span></li>
                  } else {
                    if (key === currentPage + 1 || key === currentPage - 1 || key === currentPage) {
                      return <li className={currentPage === key ? 'active' : ''} 
                      onClick={() => {
                        setCurrentPage(key)
                        setTransactionsData(arrayChunks[key])
                      }}><span>{key + 1}</span></li>
                    }
                  }
                })}




                <li onClick={(event) => nextPage(event)}><i className="fas fa-angle-right"></i></li>
            </ul>
        </div>
        </>
    )
}

export default TransactionsRecord