import React, {useState} from "react";
import Chart from "react-apexcharts";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const state = {
    series: [{
        name: 'Cluster 1',
        data: [0.1, 0.3, 0.5, 0.7, 0.4],
      }, {
        name: 'Cluster 2',
        data: [0.0, 0.1, 0.2, 0.3, 0.4],
      }, {
        name: 'Cluster 3',
        data: [0.2, 0.4, 0.6, 0.8, 0.0],
      }, {
        name: 'Cluster 4',
        data: [0.8, 0.6, 0.4, 0.2, 0.1],
      }, {
        name: 'Cluster 5',
        data: [0.0, 0.5, 0.2, 0.3, 0.7],
      }, {
        name: 'Cluster 6',
        data: [0.2, 0.4, 0.1, 0.7, 0.0],
      }, {
        name: 'Cluster 7',
        data: [0.8, 0.5, 0.1, 0.4, 0.2],
      }, {
        name: 'Cluster 8',
        data: [0.7, 0.6, 0.5, 0.4, 0.3],
      }],
      options: {
        // colors: ['#1e79b5', '#fe7a08', '#30a12c', '#9566bf ', '#ff0000', '#808080', '#8b8903', '#de87c3'],
        // dataLabels: {
        //   enabled: false
        // },
        chart: {
          height: 200,
          type: 'radar',
          offsetY: 20,
          toolbar: {
            show: false
          },
          dropShadow: {
            enabled: true,
            blur: 1,
            left: 1,
            top: 1
          }
        },
        stroke: {
          width: 2
        },
        fill: {
          opacity: 0.25
        },
        xaxis: {
            //type: 'days',
            categories: ["0", "5", "10", "15", "20"],
            // labels: {
            //     formatter: function (val) {
            //         return val + "D"
            //     },
            //     style: {
            //         colors: '#a1a1a1'
            //     }
            // },
            // title: {
            //     text: 'Days',
            //     show: false,
            //     style: {
            //         color: '#a1a1a1',
            //         fontSize: '12px',
            //         fontFamily: 'Roboto',
            //         fontWeight: 400,
            //         cssClass: 'apexcharts-xaxis-title',
            //     },
            // },
        },
        // yaxis: {
        //     categories: ["0.0", "0.2", "0.4", "0.6", "0.8"],   
        //     labels: {
        //         show: true,
        //         align: 'right',
        //         style: {
        //             colors: ['#a1a1a1']
        //         }
        //     },
        //     title: {
        //         text: 'Volatility',
        //         rotate: -90,
        //         offsetX: 0,
        //         offsetY: 0,
        //         style: {
        //             color: '#f15200',
        //             fontSize: '12px',
        //             fontFamily: 'Roboto',
        //             fontWeight: 400,
        //             cssClass: 'apexcharts-yaxis-title',
        //         },
        //     },
        // },
        legend: {
            position: 'bottom',
            horizontalAlign: 'center',
            //offsetX: 0,
            offsetY: -20,
            fontSize: '10px',
            fontWeight: 400,
            labels: {
              colors: ['#fff'],
            },
            markers: {
              width: 6,
              height: 6,
              radius: 6,
            }
        }
      },
};

const options = {
  chart: {
      type: 'column',
      width: 450,
      backgroundColor: '#333'
  },
  title: {
      text: 'Trade Volality',
      style: {
        color: '#FFF',
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
     }
  },
  subtitle: {
      text: 'Source: <a href="https://www.udecode.net" target="_blank" style="color: #FFF">DEVELOPER</a>',
      style: {
        color: '#FFF',
        font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
     }
  },
  xAxis: {
      categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
      ],
      crosshair: true,
      title: {
        // text: 'Rainfall (mm)',
        style: {
            color: '#FFF',
            font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
        }
      }
  },
  yAxis: {
      min: 0,
      title: {
        text: 'Rainfall (mm)',
        style: {
            color: '#FFF',
            font: 'bold 16px "Trebuchet MS", Verdana, sans-serif'
        }
      }
  },
  tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f} mm</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
  },
  plotOptions: {
      column: {
          pointPadding: 0.2,
          borderWidth: 0
      }
  },
  series: [{
      name: 'Tokyo',
      data: [49.9, 71.5, 106.4, 129.2, 144.0, 176.0, 135.6, 148.5, 216.4,
          194.1, 95.6, 54.4]

  }, {
      name: 'New York',
      data: [83.6, 78.8, 98.5, 93.4, 106.0, 84.5, 105.0, 104.3, 91.2, 83.5,
          106.6, 92.3]

  }, {
      name: 'London',
      data: [48.9, 38.8, 39.3, 41.4, 47.0, 48.3, 59.0, 59.6, 52.4, 65.2, 59.3,
          51.2]

  }, {
      name: 'Berlin',
      data: [42.4, 33.2, 34.5, 39.7, 52.6, 75.5, 57.4, 60.4, 47.6, 39.1, 46.8,
          51.1]

  }]
}

function TradeVolatility() {
    return (
        <div className="blockDivergent radialDivergent mt-2">
            <h6 className="chart-title-yaxis">Volatility</h6>
            {/* <HighchartsReact
                highcharts={Highcharts}
                options={options}
            /> */}
            <Chart options={state.options} series={state.series} type="radar" width={480} />
            
        </div>
    );
}

export default TradeVolatility;