import React, {useEffect, useState} from 'react';
import UserImg from 'assets/media/user.jpg';
import Logo from 'assets/media/logo.png';
import { Link } from "react-router-dom";
import { getConfig } from 'config/app-config';
import { getIdToken } from 'utils';
import { useSelector, useDispatch } from 'react-redux';
import { setBroker } from 'slices/brokerSlice';
function Header (props) {
  const dispatch = useDispatch();
  const authInfo = useSelector(selector => selector.auth);
  const [brokers, setBrokers] = useState({});
  const [loading, setLoading] = useState(true);
 

  function getBrokerList()
  {
    fetch(`${getConfig().API_URL}/users/brokers/${authInfo.user.public_id}`, {
        method: 'GET',
        headers: {
          authorization:getIdToken()
        },
      }).then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.length>0) {
          setBrokers(data)
        }
      });
  }  

  function switchBroker (event) {    
    fetch(`${getConfig().API_URL}/users/`+authInfo.user.public_id, {
      method: 'PATCH',
      body: JSON.stringify({
        default_user_broker_id: parseInt(event.target.value)
      }),
      headers: {
        authorization:getIdToken(),
        'content-type': 'application/json'
      },
    }).then(response => response.json())
    .then(data => {
      // window.location.reload();
      // console.log(data);
      // getKeyMetrices();
      // getAccounts();
    }); 
  }


  useEffect(() => {
    if (loading) {
      getBrokerList()
    }
  });

  const upperCaseDemoLive = (string=null) => {
    if(string){
      if(string.toLowerCase().includes('(live)')){
        let replacedWord = string.replace('(live)', '(LIVE)')
        return replacedWord;
      }

      if(string.toLowerCase().includes('(demo)')){
        let replacedWord = string.replace('(demo)', '(DEMO)')
        return replacedWord;
      }
    }
  }
  
  return (

        <header id="page-header">
            <div className="content-header">
              
                <div className="headleft">
                  <div className="menuMob">
                    <button type="button" className="btn btn-dual" onClick={props.togglerFunction} data-toggle="layout" data-action="sidebar">
                      <i className="fa fa-fw fa-bars"></i>
                    </button>
                    <Link className="logoMob" to="/">
                      <img src={Logo} alt="Divergence" />
                    </Link>
                  </div>

                  
                  <div className="menuDesktop">
                    <h3><span>Unlock the Pro Version</span><i className="fa fa-lock"></i></h3>
                    <form className="headerSearch" action="" method="POST">
                        <input type="text" className="form-control" placeholder="Search or hit ESC.." id="page-header-search-input" name="page-header-search-input" />
                        <button type="button" className="btn btn-dual" data-toggle="layout" data-action="header_search_on">
                          <i className="fa fa-search"></i>
                        </button>
                    </form>
                  </div>
                </div>
              
                <div className="headright">                  
                  <p>Strategy:  
                  { authInfo.user.risk === 1 ? ' Conservative' : null }
                  { authInfo.user.risk === 2 ? ' Moderate' : null }
                  { authInfo.user.risk === 3 ? ' Aggressive' : null } 
                  <span>
                  {0 < brokers.length ? 
                  <select className='form-control custom_select_form' onChange={(event) => { 
                    switchBroker(event)
                    dispatch(setBroker(parseInt(event.nativeEvent.target[event.nativeEvent.target.selectedIndex].id)))
                  }}>
                    {brokers.map((result, key) => {
                       return <option value={result.id} selected={result.id === authInfo.user.default_user_broker_id ? 'selected' : ''} id={result.broker_id}>{ upperCaseDemoLive(result.broker.title)}</option>
                    })}
                  </select> : ''}
                  </span></p>
                  <div className="headuser">
                    <h5>Grace Brown</h5>
                    <a className="img-link mr-1" href="javascript:;">
                      <img className="img-avatar" src={UserImg} alt="" />
                      <span className="status online"></span>
                    </a>
                  </div>
                </div>
              
            </div>
    
            <div id="page-header-loader" className="overlay-header bg-header-dark">
              <div className="bg-white-10">
                <div className="content-header">
                  <div className="w-100 text-center">
                    <i className="fa fa-fw fa-sun fa-spin text-white"></i>
                  </div>
                </div>
              </div>
            </div>
          </header>
    )
}

export default Header;